import * as React from "react";
import { Button, Modal, Typography } from "components";
import styles from "./CookiePolicy.module.css";

const CookiePolicy = () => {
  // todo: set cookie instead of local storage?
  const [isInfoModal, setIsInfoModal] = React.useState(false);
  const [hasWarning, setHasWarning] = React.useState(
    localStorage.getItem("cookies-accepted") !== "true"
  );

  const acceptCookies = () => {
    setHasWarning(false);
    localStorage.setItem("cookies-accepted", "true");
  };

  const showCookieInfo = () => {
    setIsInfoModal(true);
  };

  return (
    <>
      <Button
        className={styles.browserCookies}
        type="link"
        onClick={showCookieInfo}
      >
        Cookie Policy
      </Button>{" "}
      {hasWarning && (
        <div className={styles.warning}>
          The Zephyr Maintenance website uses cookies, which make the site
          simpler to use. Find out more about{" "}
          <Button
            className={styles.browserCookies}
            type="link"
            onClick={showCookieInfo}
          >
            browser cookies
          </Button>{" "}
          <Button
            className={styles.acceptButton}
            type="primary"
            size="small"
            onClick={acceptCookies}
          >
            Ok
          </Button>
        </div>
      )}
      <Modal
        title="Cookie Policy"
        visible={isInfoModal}
        onCancel={() => setIsInfoModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={null}
        className={styles.modal}
      >
        <Typography.Paragraph>
          If you choose not to use cookies during your visit, you should be
          aware that certain functions and pages will not work as expected.
        </Typography.Paragraph>

        <Typography.Paragraph>
          As an example you will not be able to log in, because cookies are
          required for authentication.
        </Typography.Paragraph>

        <Typography.Paragraph>
          If you want to delete any cookies already on your computer, please
          refer to your browser vendor&apos;s instructions by clicking
          &quot;Help&quot; in your browser menu.
        </Typography.Paragraph>

        <Typography.Paragraph>
          You can also find out more about cookies and how to delete and control
          them on{" "}
          <Typography.Link href="https://www.aboutcookies.org/" target="_blank">
            www.aboutcookies.org
          </Typography.Link>{" "}
          or click &quot;Help&quot; in your browser menu.
        </Typography.Paragraph>
      </Modal>
    </>
  );
};

export default CookiePolicy;
