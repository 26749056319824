import React from "react";
import { TooltipItem } from "chart.js";
import { Chart as ChartJS } from "react-chartjs-2";
import { Typography } from "components";
import { Legend } from "..";
import { Types } from "../../duck";
import styles from "./Doughnuts.module.css";

interface DoughnutProps {
  data: Types.DataChart;
}

const Doughnut: React.FC<DoughnutProps> = ({ data }) => {
  const { nodes, total, title } = data;

  return (
    <div className={styles.wrapper}>
      <div className={styles.chart}>
        <div className={styles.title} style={{}}>
          <Typography>Total</Typography>
          <Typography>{title}</Typography>
        </div>
        <ChartJS
          type="doughnut"
          data={{
            labels: nodes.map((item) => item.id),
            datasets: [
              {
                label: "Doughnut",
                data: nodes,
                backgroundColor: nodes.map((item) => item.color),
                parsing: {
                  key: "value",
                },
              },
            ],
          }}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            aspectRatio: 1,
            cutout: "65%",
            elements: {},
            offset: 4,
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  // TODO: remove any type if it possible
                  label(tooltipItem: TooltipItem<any>): string | string[] {
                    return `${tooltipItem.label}: ${tooltipItem.parsed}/${
                      total
                        ? Math.round((100 / total) * tooltipItem.parsed * 10) /
                          10
                        : 0
                    }%`;
                  },
                },
              },
            },
          }}
        />
      </div>

      <div className={styles.legend}>
        <Legend data={nodes} total={total} />
      </div>
    </div>
  );
};

export default Doughnut;
