import * as React from "react";
import * as C from "components";
import { useList } from "hooks";
import { Types } from "../../duck";

const Dashboard: React.FC = () => {
  const { data, loading, pagination, onChange, order } =
    useList<Types.GetMaintenancesQuery>();

  return (
    <C.Table
      dataSource={data?.maintenances.nodes}
      pagination={pagination}
      order={order}
      loading={loading}
      onChange={onChange}
      rowKey={(record) => record.woId}
    >
      <C.Table.IDColumn
        dataIndex="identifier"
        render={(identifier: string, { assetId }: Types.Maintenance) => (
          <C.Link to={`/assets/${assetId}`}>{identifier}</C.Link>
        )}
        sorter
      />

      <C.Table.Column dataIndex="make" title="Make" sorter />

      <C.Table.Column dataIndex="model" title="Model" sorter />

      <C.Table.Column dataIndex="nextService" title="Next Service" sorter />

      <C.Table.Column
        dataIndex="schedule"
        title="Schedule"
        sorter
        render={(name, { scheduleId }: Types.Maintenance) => (
          <C.Link to={`/schedule-templates/${scheduleId}`}>{name}</C.Link>
        )}
      />

      <C.Table.Column dataIndex="category" title="Category" sorter />

      <C.Table.Column dataIndex="location" title="Location" sorter />

      <C.Table.Column dataIndex="site" title="Site" sorter />

      <C.Table.Column
        dataIndex="milesTillDue"
        title="Miles Till Due"
        align="center"
        render={(miles, { uwtMileage }: Types.Maintenance) => (
          <C.Due value={miles} warningAt={uwtMileage} />
        )}
        sorter
      />

      <C.Table.Column
        dataIndex="hoursTillDue"
        title="Hours Till Due"
        align="center"
        render={(hours, { uwtEngineHour }: Types.Maintenance) => (
          <C.Due value={hours} warningAt={uwtEngineHour} />
        )}
        sorter
      />

      <C.Table.Column
        dataIndex="daysTillDue"
        title="Days Till Due"
        align="center"
        render={(days, { uwtTime }: Types.Maintenance) => (
          <C.Due value={days} warningAt={uwtTime} />
        )}
        sorter
      />

      <C.Table.Column
        dataIndex="wo"
        title="WO#"
        render={(wo, { woId }: Types.Maintenance) => {
          if (wo) return <C.Link to={`/work-order/${woId}/edit`}>{wo}</C.Link>;
        }}
        sorter
      />

      <C.Table.Column
        width={110}
        dataIndex="status"
        title="WO Status"
        key="status"
        render={(status, { woId }: Types.Maintenance) => {
          if (woId)
            return <C.WorkOrderStatusSelect id={woId} status={status} />;
        }}
        sorter
      />
    </C.Table>
  );
};

export default Dashboard;
