import * as React from "react";
import { FormProps } from "antd";
import { useFormikContext } from "formik";
import * as Formik from "formik-antd";
import { usePrevious } from "hooks";
import * as C from "./components";
import { utils } from "./duck";

const Form = (props: FormProps): React.ReactElement => {
  const { errors, isSubmitting } = useFormikContext();
  const prevIsSubmitting = usePrevious(isSubmitting);
  const firstErrorName = Object.keys(errors)[0];

  React.useEffect(() => {
    // scroll to errors after submit
    if (firstErrorName && prevIsSubmitting && !isSubmitting) {
      utils.scrollErrorIntoView(firstErrorName);
    }
  }, [firstErrorName, isSubmitting, prevIsSubmitting]);

  return <Formik.Form {...props} />;
};

Form.Controls = C.Controls;
Form.Select = C.Select;
Form.TreeSelect = C.TreeSelect;
Form.Upload = C.Upload;
Form.InputSelect = C.InputSelect;

Form.Checkbox = Formik.Checkbox;
Form.DatePicker = Formik.DatePicker;
Form.Field = Formik.Field;
Form.Input = Formik.Input;
Form.InputNumber = Formik.InputNumber;
Form.Item = Formik.FormItem;
Form.Radio = Formik.Radio;
Form.Switch = Formik.Switch;

export default Form;
