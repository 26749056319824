import * as React from "react";
import PhoneNumber from "awesome-phonenumber";
import ReactPinInput from "react-pin-input";
import * as C from "components";
import { useAuth, useLocation, useMutation, useNavigate } from "hooks";
import { cx, isAdminService, onError } from "utils";
import { operations, Types } from "./duck";
import styles from "./Confirm.module.css";

interface ConfirmProps {
  phoneNumber: string;
  email: string;
}

const CODE_INPUTS_COUNT = 6;

interface FormValues {
  passcode: string;
}

const Confirm: React.FC<ConfirmProps> = ({ phoneNumber, email }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [auth] = useAuth();
  const [passcode, setPasscode] = React.useState<string>("");
  const isValid = passcode.length === CODE_INPUTS_COUNT;

  const state = location.state as { from: Location };
  const from = state ? state.from.pathname : "/";
  const sentCodeToText = phoneNumber ? "mobile phone" : "email";

  const signInCompleted = (user: Types.User, token: string) => {
    auth.signIn(user, token);
    navigate(from, { replace: true });
  };

  const [signIn] = useMutation<
    Types.SignInMutation,
    Types.SignInMutationVariables
  >(isAdminService() ? operations.signInAdmin : operations.signInClient, {
    onCompleted: (data) => {
      const user =
        "signinAdminUser" in data
          ? data.signinAdminUser.adminUser
          : data.signinClientUser.user;

      const token =
        "signinAdminUser" in data
          ? data.signinAdminUser.authToken
          : data.signinClientUser.authToken;

      signInCompleted(user, token);
    },
    onError,
  });

  const [verifyUserResend] = useMutation<
    Types.VerifyUserResendMutation,
    Types.VerifyUserResendMutationVariables
  >(
    isAdminService()
      ? operations.verifyUserAdminResend
      : operations.verifyUserClientResend,
    {
      onCompleted: () => {
        C.message.success("Code sent successfully");
      },
      onError,
    }
  );

  const onResend = () => {
    // TODO: change mutations after backend (maybe)
    verifyUserResend({
      variables: {
        credentials: {
          ...(email ? { email } : {}),
          ...(phoneNumber ? { phoneNumber } : {}),
        },
      },
    });
  };

  const onSubmit = () => {
    signIn({
      variables: {
        ...(email ? { email } : {}),
        ...(phoneNumber
          ? {
              phoneNumber: PhoneNumber(`${phoneNumber}`).getNumber("e164"),
            }
          : {}),
        passcode,
      },
    });
  };

  return (
    <>
      <C.Space className={styles.textCenter} direction="vertical" size="large">
        <C.Typography.Title level={5}>Enter Passcode</C.Typography.Title>
        <C.Typography>
          Code sent to:{" "}
          <C.Typography.Text className={styles.primaryText}>
            {phoneNumber ? `${phoneNumber}` : email}
          </C.Typography.Text>
        </C.Typography>
        <C.Typography>
          The login passcode has been sent to your {sentCodeToText}. Please
          enter the code below to access the maintenance portal{" "}
          <C.Link to="" onClick={() => navigate(-1)}>
            or use a different {sentCodeToText}.
          </C.Link>
        </C.Typography>
      </C.Space>

      <C.Formik<FormValues>
        onSubmit={onSubmit}
        initialValues={{ passcode: "" }}
      >
        <C.Form className={styles.block} name="confirm-form">
          <div className={styles.codeInputContainer}>
            <ReactPinInput
              type="numeric"
              inputMode="number"
              length={6}
              initialValue=""
              onChange={setPasscode}
              focus
            />
          </div>
          <C.Space className={cx(styles.hCenter, styles.block)}>
            <C.Button onClick={onResend}>Resend Code</C.Button>
            <C.Button disabled={!isValid} type="primary" htmlType="submit">
              Sign In
            </C.Button>
          </C.Space>
        </C.Form>
      </C.Formik>
    </>
  );
};

export default Confirm;
