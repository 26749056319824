import * as React from "react";
import { Typography } from "components";
import styles from "./Support.module.css";

const Support: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      <div>
        <Typography.Title level={4}>WE ARE HERE TO HELP!</Typography.Title>
        <Typography.Text>
          For assistance, please contact:{" "}
          <Typography.Link>x@x.x</Typography.Link>
        </Typography.Text>
      </div>
    </div>
  );
};

export default Support;
