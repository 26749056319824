import * as React from "react";
import { Tag } from "antd";
import { FieldProps, useField } from "formik";
import { FormikFieldProps } from "formik-antd/lib/FieldProps";
import { find } from "lodash-es";
import * as C from "components";
import Select, { SelectProps } from "../../../select";
import { utils } from "./duck";
import styles from "./FormInputSelect.module.css";

type FormSelectProps = SelectProps & FormikFieldProps;

function FormInputSelect({
  name,
  onChange,
  onBlur,
  onSelect,
  options,
  optionLabel = "label",
  query,
  ...restProps
}: FormSelectProps): React.ReactElement {
  const [{ value }, , { setValue }] = useField(name);

  const prepareValue = (v: string | undefined, option?: any) => {
    if (!v) return [];

    const existedOption = find(
      options,
      (o) => o[optionLabel].toLowerCase().trim() === v?.toLowerCase().trim()
    );
    return utils.generateOnSelect(
      !option?.value && !existedOption,
      existedOption ? existedOption.id : v
    );
  };

  return (
    <C.Field name={name}>
      {({ form: { setFieldValue, setFieldTouched } }: FieldProps) => {
        return (
          <Select
            options={options}
            onChange={(changedValue, valueOptions) => {
              setFieldValue(name, changedValue);
              const newValue = prepareValue(
                changedValue.pop(),
                valueOptions.pop()
              );

              if (onChange) {
                /**
                 *  Before setValue variable changedValues contains previous array of values including
                 *  last selected. So prepare only last selected value:
                 */
                onChange(newValue, valueOptions);
              } else {
                setValue(newValue);
              }
            }}
            onBlur={(event) => {
              setFieldTouched(name);
              onBlur?.(event);
            }}
            onSelect={(selectedValue, option) => {
              setFieldTouched(name);

              if (onSelect) {
                onSelect(prepareValue(selectedValue), option);
              }
            }}
            showSearch
            optionFilterProp="label"
            value={value.map((item: { id: string }) => item.id)}
            mode="tags"
            tagRender={(tag) => (
              <Tag className={styles.tag} closable={false}>
                <span>{tag.label}</span>
              </Tag>
            )}
            optionLabel={optionLabel}
            {...restProps}
          />
        );
      }}
    </C.Field>
  );
}

FormInputSelect.Option = Select.Option;

export default FormInputSelect;
