import { Filter, Order, Pagination, Sort, Search } from "types";

export const prepareVariables = <TVariables>(
  pagination: Pagination,
  sortBy: Sort | undefined | null,
  filter: Filter | undefined | null,
  search: Search | undefined | null,
  orderBy: Order | undefined | null,
  variables: TVariables
) => {
  return {
    pageNumber: pagination.number,
    pageSize: pagination.size,
    sortBy,
    orderBy,
    filter,
    search,
    ...variables,
  };
};
